import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Header from '../components/Header';

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  return (
    // <Layout>
    //   <Header />
    <div className="blog-post-container">
      <div className="blog-post">
        <header>
          <a href="/">
            <h2>Deep Habits</h2>
          </a>
        </header>
        <h1>{frontmatter.title}</h1>
        <h2>{frontmatter.date}</h2>
        <h3>This is a page, not a blog post!</h3>
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </div>
    // </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`;
